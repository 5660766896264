import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"
import Cta from "../components/cta"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const imageData = post.frontmatter.featuredImage ? getImage(post.frontmatter.featuredImage.childImageSharp.gatsbyImageData) : undefined;
//   const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        pathname={location.pathname}
      />
        <div className="blog-bar">
            <div className="container">
                <div className="row">
                    <Link to="/blog" className="arrow-left white">
                        <ArrowRight />
                        Back to blog
                    </Link>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <article
                    className="blog-post"
                    itemScope
                    itemType="http://schema.org/Article"
                >
                    <h1 itemProp="headline">{post.frontmatter.title}</h1>
                    <small className="black-lt">By <Link to="/author">{post.frontmatter.author}</Link>, {post.frontmatter.date}</small>
                    <div className="blog-post-img">
                        {imageData && <GatsbyImage image={imageData} alt={post.frontmatter.alt} />}
                    </div>
                    <section
                    dangerouslySetInnerHTML={{ __html: post.html }}
                    itemProp="articleBody"
                    /> 
                </article>
                {/* <nav className="blog-post-nav">
                    <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                        width: '100%',
                    }}
                    >
                    <li>
                        {previous && (
                        <Link to={previous.fields.slug} rel="prev" className="arrow-left">
                            <ArrowRight /> {previous.frontmatter.title}
                        </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                        <Link to={next.fields.slug} rel="next" className="with-arrow">
                            {next.frontmatter.title} <ArrowRight />
                        </Link>
                        )}
                    </li>
                    </ul>
                </nav> */}
            </div>
        </div>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <Cta
        text="Contact Launch Lab for web design and web development quotes from a local onshore Australian design & development team"  
        />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        alt
        author
        featuredImage {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 1.8
                    layout: FULL_WIDTH
                    quality: 100
                    transformOptions: {cropFocus: CENTER}
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
